.ul-rs{
    margin-top: 0px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	@include media-breakpoint-down('md'){
		justify-content: center;
	}
	.item-rs {
		margin: 0px 6px;
		list-style: none;
		padding: 0px;
		&:first-child{
			margin-left: 0px;
		}
		&:last-child{
			margin-right: 0px;
		}
		.picto{
			display: inline-flex;
			justify-content: center;
			align-items: center;
			padding: 7px;
			height: 32px;
			width: 32px;
			margin: 0px;
			background: $white;
			transition: 0.2s linear;
			svg{
				height: 15px;
				width: 15px;
                [fill^="#"]{
	    			transition: 0.2s linear;
                    fill : $dark-blue;
                }
                [stroke^="#"]{
	    			transition: 0.2s linear;
                    stroke : $dark-blue;
                }
			}
			
		}
		a{
			&:hover{
				.picto{
					background: $red;
					svg{
			    		[fill^="#"]{
		                    fill : $white;
		                }
		                [stroke^="#"]{
		                    stroke : $white;
		                }
			    	}
				}
		    	
			}
		}
	}

	&.xxl{
		.item-rs {
			margin: 0px 10px;
			.picto{
				height: 40px;
				width: 40px;
				svg{
					height: 22px;
					width: 22px;
				}			
			}			
		}
	}

	&.red-white{
		.item-rs a{
			.picto{
				background: $red;
				svg{
	                [fill^="#"]{fill : $white;}
	                [stroke^="#"]{fill : $white;}
				}
			}
			&:hover{
				.picto{
					background: $dark-blue;
					svg{
		                [fill^="#"]{fill : $white;}
		                [stroke^="#"]{fill : $white;}
					}
				}
			}
		}
	}

	&.red{
		.item-rs a{
			.picto{
				background: $red;
				svg{
	                [fill^="#"]{fill : $dark-blue;}
	                [stroke^="#"]{fill : $dark-blue;}
				}
			}
			&:hover{
				.picto{
					background: $dark-blue;
					svg{
		                [fill^="#"]{fill : $white;}
		                [stroke^="#"]{fill : $white;}
					}
				}
			}
		}
	}
}