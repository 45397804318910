.section-press{
	.list-press{
		.item-press-ctr{
			@include media-breakpoint-down(sm){
				flex: 0 0 50%;
				max-width: 50%;
			}
		}
		.item-press{
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 20px;


		}
	}
}