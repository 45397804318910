.section-breadcrumb{
	a{
		text-decoration: underline;
	}
	p,a,span{
		padding: 0 5px;
	}

	&.text-white{
		p,a,span{
			color: $white ;
		}
	}
}