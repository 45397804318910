/*
|--------------------
|      FOOTER
|--------------------
*/


#footer {
  &.footer-top {
    padding-top: 70px;
    padding-bottom: 20px;
    @include media-breakpoint-down(md) {
      padding-top: 50px;
      padding-bottom: 10px;
    }
  }

  .footer-bottom {
    margin-top: 50px;
    padding-top: 30px;
    border-top: 1px solid $dark-blue;
  }

  .title-footer {
    color: $red;
    font-weight: 500;
    margin-bottom: 10px;
  }

  a {
    transition: 0.2s linear;

    &:hover {
      text-decoration: none;
      color: $red;
    }
  }

  #ot-sdk-btn {
    color: $dark-blue;
    border: 0px;
    font-size: 14px;
    padding: 0;

    &:hover {
      background: none;
      color: $red;
      transition: 0.2s linear;
    }
  }

  .list-item {
    .item-add {
      color: $dark-blue;

      &:before {
        content: '•';
        font-size: 8px;
        display: inline-block;
        margin-right: 10px;
        margin-left: 5px;
        transform: translateY(-3px);
      }

      &:last-child {
        &:after {
          content: '•';
          font-size: 8px;
          display: inline-block;
          margin-right: 5px;
          margin-left: 10px;
          transform: translateY(-3px);
        }
      }
    }
  }

  .item-link {
    color: $grey;
    transition: 0.2s linear;
    margin-bottom: 10px;
    font-size: 16px;
    @include media-breakpoint-down(md) {
      margin-bottom: 0px;
    }

    &:hover {
      text-decoration: none;
      color: $red;
    }
  }


  .col {
    flex: 0 0 19%;
    max-width: 19%;
    @include media-breakpoint-down(md) {
      flex: 0 0 100%;
      max-width: 100%;
      margin-bottom: 20px;
    }
  }

  .col-text {
    flex: 0 0 22%;
    max-width: 22%;
    @include media-breakpoint-down(md) {
      flex: 0 0 100%;
      max-width: 100%;
      margin-bottom: 20px;
    }

    .cms > p {
      margin-bottom: 5px;
    }
  }

  .col-btn {
    padding-left: 0px;
    padding-right: 0px;
    @include media-breakpoint-down(md) {
      margin-top: 25px;
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .ul-rs {
    @include media-breakpoint-down(md) {
      justify-content: center;
      margin-top: 10px;
    }
  }

  .col-nav {
    flex: auto;
    @include media-breakpoint-down(md) {
      flex: 0 0 100%;
      max-width: 100%;
    }
    @include media-breakpoint-down(md) {
      padding-left: 15px;
      padding-right: 15px;
      margin-bottom: 0;
    }

    .item-menu {
      display: flex;
      flex-direction: column;
      @include media-breakpoint-down(md) {
      }
      @include media-breakpoint-down(md) {
        .name-menu {
          text-align: center;
        }
      }

      .menu-item {
        margin-bottom: 2px;


      }
    }
  }

  @include media-breakpoint-down(md) {
    text-align: center;
    .col {
      margin-bottom: 15px;
    }
  }

  .col-btn {
    img{
      float: left;
      height: 30px;
    }
  }
}