[data-item-video]{
	position: absolute;
  right: 0;
  bottom: 0;
  object-fit: cover;
  min-width: 100%;
  min-height: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
	
}
