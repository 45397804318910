/*
|
| Contact form 
|---------------
*/
.block-form{
    .gform_body{
        label{
            color: $very-dark-grey;
        }
        input:not([type=checkbox]), textarea, select{
            -webkit-appearance: none;
            border-radius: 4px;
            padding: 18px 20px;
            border: 1px solid $dark-blue;
            font-size: 16px;
            color: $dark-blue;
            transition: 0.2s linear;
            background: transparent;
            &:focus{
                outline: none;
                border: 1px solid $red;
            }
            &::placeholder{
                font-weight: 400;
            }
        }
        select{
            background-image: url('../img/icon/ico-down.svg');
            background-repeat: no-repeat;
            background-size: 10px;
            background-position: calc(100% - 10px) center;
            padding-right: 50px;
        }

        textarea{
            height: 200px;

        }
        .file-rgpd{
            .gfield_label{
                display: none;
            }
            .gfield_description{
                padding-left: 15px;
            }
            .ginput_container{
                display: flex;
                input{
                    width: auto;
                    margin: 7px 20px;
                }
                .gfield_consent_label{
                }
            }
            a{
                color: $dark-blue;
                padding-left:5px;
                text-decoration: underline;
                &:hover{
                    color: $red;
                }
            }
        }
        .gform_fields{
            @extend .row;
            input, textarea, select{
                width: 100%;
            }
            .gfield{
                margin-bottom: 20px;
            }
        }
        .gfield_description{
        }
        .gform_body{
            margin-bottom: 20px;
        }
        .gfield_label{
            font-weight: 400;
            margin-bottom: 10px;
            font-size: 16px;
        }
        
       
        .gform_confirmation_message{
            color: $very-dark-grey;
        }

        .validation_message{
            margin-top: 5px;
            color: #BB0B0B;
            font-size: 14px;
        }
    }
    .gform_footer {
        text-align: right;
        padding-top: 20px;
        button[type="submit"]{
        }
    }
}


.validation_error{
    margin-bottom: 30px;
    color: #BB0B0B!important;
}

#gform_ajax_spinner_1 {
    //display: none;
    margin-left: 10px;
    display: inline-block;
    width: 50px;
    height: 50px;
}