.section-card-actu{
	
}

.card-actu-ctr{
	margin-bottom: 20px;
	&.card-reference{
		.adv-image--actu{
			.img-cover figure {
				display: flex;
				justify-content: center;
				align-items: center;
				.img-fluid{
					object-fit: contain;
					height: 50%;
					width: 40%;
					object-position: center;
				}
			}
		}
	}
}
.card-actu{
	width: 100%;
	.adv-image--actu{
		margin: 0;
		width: 100%;
		border: 1px solid $dark-blue;
		transition: 0.2s linear;
	}
	.card-content{
		position: relative;
		z-index: 5;
		flex: 1;
		padding-top: 20px;
		padding-bottom: 20px;
		.title{
			transition: 0.2s linear;
		}
		.btn-circle{
			position: absolute;
			top: 0;
			right: 0;
			transform: translate(0%,-50%);
			opacity: 0;
			transition: 0.2s ease-In-Out;
		}
	}
	&:hover{
		text-decoration: none;
		.adv-image--actu{
			border-color: $red;
		}
		.card-content{
			.title{
				color: $red;
			}
			.btn-circle{
				transform: translate(-50%,-50%);
				opacity: 1;
			}
		}
	}
}

