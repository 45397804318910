.section-outils{
	.swiper-outils{
		.swiper-slide{
			height: auto;
		}
		.item-outil{
			height: 100%;
			img{
				height: 80%;
				width: 90%;
				max-height: 120px;
				object-fit: contain;
			}
		}
	}
}

