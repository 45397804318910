/*
|--------------------
|      NEWS
|--------------------
*/
.section-title-news{
	.title{
		font-size: 42px;
	}
}
.load-more-container {
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    .loader-container {
        display: none;
    }
}

#filter-cat{
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	align-items: center;
	margin-bottom: 100px;
	width: max-content;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
    @include media-breakpoint-down(sm) {
		margin-bottom: 50px;
    }
	.item-filter{
		margin: 0 15px;
	    @include media-breakpoint-down(sm) {
	    	margin: 5px 5px;
	    	margin-bottom: 15px;
    	}
		a{
			position: relative;
			padding: 18px 40px;
			position: relative;
			color: $red;
			border: 1px solid $red;
			transition: 0.2s linear;
			border-radius: 50px;
		    @include media-breakpoint-down(sm) {
		    	font-size: 13px;
		    }
			&:hover{
				background-color: $red;
				color: $white;
			}
		}
		&.active{
			a{
				background-color: $red;
				color: $white;
				
			}
		}
	}
}
.item-legend{
	position: relative;
	display: block;
	padding-top: 20px;
	padding-bottom: 10px;
}
.pagination-container{
    display: flex;
    align-items: center;
	justify-content: center;
	margin-top: 80px;
    li{
    	margin-right: 5px; 
    	&:last-child{
    		margin-right: 0px;
    	}
    	a,span{
		    min-width: 50px;
			height: 50px;
    		display: flex;
    		justify-content: center;
    		align-items: center;
    		font-style: 16px;
    		padding: 10px !important;
    		font-weight: 400;
    	}

    	.current{
    		border: 2px solid $red;
    		font-weight: 400;
    	}
    }
}