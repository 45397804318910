
.section-services{
	padding-top: 60px;
	padding-bottom: 60px;
	.list-services{
		.item-services-ctr{
			@include media-breakpoint-down(md){
				margin-bottom: 20px;
				&:last-child{
					margin-bottom: 0px;
				}		
			}
		}
	}
}

.item-services{
	position: relative;
	text-align: center;
	padding: 0 0 20px 0px;
	margin-bottom: 55px;
	&:after{
		position: absolute;
		bottom: 0px;
		left: 0px;
		right: 0px;
		margin: auto;
		height: 2px;
		width: 30px;
		background: $orange;
	}	
	.services-image{
		margin: auto;
	    justify-content: center;
	    align-items: center;
	    display: flex;
	    width: 100px;
	    height: 100px;
	    margin-bottom: 20px;
	    img{
	    	object-fit: contain;
	    	width: 80%;
	    	height: 80%;
	    }
	}
	.services-title{
	    margin-bottom: 20px;
	    color: $orange;
	}

	.services-nb{
		font-size: 40px;
		line-height: 1.2em;
	    margin-bottom: 10px;
	}
	.services-content{
		line-height: 1.4em;
	}
}