/*
|--------------------
|    FONTS IMPORTS
|--------------------
|
| 100 - Extra Light or Ultra Light
| 200 - Light or Thin
| 300 - Book or Demi
| 400 - Normal or Regular
| 500 - Medium
| 600 - Semibold, Demibold
| 700 - Bold
| 800 - Black, Extra Bold or Heavy
| 900 - Extra Black, Fat, Poster or Ultra Black
|
*/

@font-face {
    font-family: 'helvetica-neue';
    src: url('../fonts/helvetica-neue/helveticaneuelt-webfont.eot');
    src: url('../fonts/helvetica-neue/helveticaneuelt-webfont.eot?#iefix') format('embedded-opentype'),
        url('../fonts/helvetica-neue/helveticaneuelt-webfont.woff2') format('woff2'),
        url('../fonts/helvetica-neue/helveticaneuelt-webfont.woff') format('woff'),
        url('../fonts/helvetica-neue/helveticaneuelt-webfont.ttf') format('truetype'),
        url('../fonts/helvetica-neue/helveticaneuelt-webfont.svg#helveticaneuelight') format('svg');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'helvetica-neue';
    src: url('../fonts/helvetica-neue/helveticaneue_medium-webfont.eot');
    src: url('../fonts/helvetica-neue/helveticaneue_medium-webfont.eot?#iefix') format('embedded-opentype'),
        url('../fonts/helvetica-neue/helveticaneue_medium-webfont.woff2') format('woff2'),
        url('../fonts/helvetica-neue/helveticaneue_medium-webfont.woff') format('woff'),
        url('../fonts/helvetica-neue/helveticaneue_medium-webfont.ttf') format('truetype'),
        url('../fonts/helvetica-neue/helveticaneue_medium-webfont.svg#helveticaneuemedium') format('svg');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'helvetica-neue';
    src: url('../fonts/helvetica-neue/helveticaneuebd-webfont.eot');
    src: url('../fonts/helvetica-neue/helveticaneuebd-webfont.eot?#iefix') format('embedded-opentype'),
        url('../fonts/helvetica-neue/helveticaneuebd-webfont.woff2') format('woff2'),
        url('../fonts/helvetica-neue/helveticaneuebd-webfont.woff') format('woff'),
        url('../fonts/helvetica-neue/helveticaneuebd-webfont.ttf') format('truetype'),
        url('../fonts/helvetica-neue/helveticaneuebd-webfont.svg#helveticaneuebold') format('svg');
    font-weight: 600;
    font-style: normal;
}


@font-face {
    font-family: 'GT-America';
    src: url('../fonts/GTAmerica/GTAmerica-Regular.eot');
    src: url('../fonts/GTAmerica/GTAmerica-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/GTAmerica/GTAmerica-Regular.woff2') format('woff2'),
        url('../fonts/GTAmerica/GTAmerica-Regular.woff') format('woff'),
        url('../fonts/GTAmerica/GTAmerica-Regular.ttf') format('truetype'),
        url('../fonts/GTAmerica/GTAmerica-Regular.svg#GTAmerica-Regular') format('svg');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'GT-America-ExtendedBlack';
    src: url('../fonts/GTAmerica/GTAmerica-ExtendedBlack.eot');
    src: url('../fonts/GTAmerica/GTAmerica-ExtendedBlack.eot?#iefix') format('embedded-opentype'),
        url('../fonts/GTAmerica/GTAmerica-ExtendedBlack.woff2') format('woff2'),
        url('../fonts/GTAmerica/GTAmerica-ExtendedBlack.woff') format('woff'),
        url('../fonts/GTAmerica/GTAmerica-ExtendedBlack.ttf') format('truetype'),
        url('../fonts/GTAmerica/GTAmerica-ExtendedBlack.svg#GTAmerica-ExtendedBlack') format('svg');
    font-weight: 600;
    font-style: normal;
}


