.section-card-actu{
	
}

.card-circle-ctr{
	margin-bottom: 20px;
}
.card-circle{
	width: 100%;
	.adv-image--1by1{
		margin: 0;
		width: 120px;
		transition: 0.2s linear;
		border-radius: 100%;
		overflow: hidden;
		background: $light-grey;
	}
	.card-content{
		position: relative;
		z-index: 5;
		flex: 1;
		padding-top: 40px;
		padding-bottom: 20px;
		.title{
			transition: 0.2s linear;
		}
		
	}

	&:hover{
		.title{
			color: $red;
			cursor: pointer;
		}
		/*text-decoration: none;
		.adv-image--actu{
			border-color: $red;
		}
		.card-content{
			.title{
				color: $red;
			}
		}

		*/
	}
}

