.section-expertises{
	position: relative;
	padding-top: 100px;
	padding-bottom: 100px;
	background: $dark-blue;

    @include media-breakpoint-down(sm) {
	    padding-top: 80px;
	    padding-bottom: 80px;
	}

	.container-fluid{
		position: relative;
		z-index: 10;
	}
	.swiper-expertise{
    	width: 100%;
	    .swiper-container{
	    	height: 100%;
	    }

	    .swiper-slide{
		  	overflow: hidden;
		  	backface-visibility:hidden;
		}
			
		.adv-image{
			background-color: $grey;
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
		}
	}
}
.section-all-expertises{
	margin-top: 0 !important;
	padding-top: 230px !important;
	padding-bottom: 50px !important;
    @include media-breakpoint-down(md) {
		padding-top: 50px !important;
	}
}
.section-all-medias{
	padding-top: 150px !important;
    @include media-breakpoint-down(md) {
		padding-top: 10px !important;
	}
}
.section-sub-menu{
	padding-top: 50px;
	padding-bottom: 50px;
	z-index: 555;
	transition: 0.2s linear;
	position: absolute;
	width: 100%;
    @include media-breakpoint-down(md) {
		padding-top: 30px;
		padding-bottom: 0px;
		position: relative;
	}
	&.is-fixed{
		background-color: $white;
        box-shadow: 0px 6px 13px #00000014;
        padding-top: 20px;
		padding-bottom: 20px;
	}
	.list-sub-menu{
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		align-items: center;
		width: 100%;
	    @include media-breakpoint-down(sm) {
   			flex-wrap: inherit;
	    }
		.item-nav{
			color: $red;
			opacity: 0.5;
			font-weight: 600;
			&.active{
				opacity: 1;
			}
			a{
				padding: 10px 20px;
			}
		}
	}
}


.list-nav-expertise{
	display: flex;
	flex-direction: column;
	@include media-breakpoint-down(sm){
		margin-top: 20px;
		margin-bottom: 40px;
	}
	.item-expertise{
		a.item-expertise-link{
			display: block;
			position: relative;
			font-weight: 600;
			transition: 0.2s linear;
			color: $white;
			span{
				padding: 25px 100px 25px 0;
				position: relative;
				opacity: .5;
				@include media-breakpoint-down(sm) {
					padding: 15px 80px 5px 0;
				}
			}
			.btn-circle{
				position: absolute;
				top: 0;
				left: auto;
				right: 0;
				margin: auto;
				opacity: 0;
				transform: translateX(80px);
			}
			&:hover{
				span{
					opacity: 1;
				}
				.btn-circle{
					opacity: 1;
					transition: 0.2s ease-In-Out;
					transform: translateX(0%);
				}
			}
		}
	}
}




.list-expertise{
	margin-top: 40px;
	.card-expertise-ctr{
		margin-bottom: 30px;
	}
}

.card-expertise{
	background: $grey;
	width: 100%;
	color: $white;
	display: block;
	overflow: hidden;
	.adv-image{
		&:after{
			content: '';
			position: absolute;
			z-index: 5;
			left: 0;
			top: 0;
			bottom: 0;
			right: 0;
			opacity: 0;
			background: $black;
			transition: 0.22s ease-in-out;
	        @include media-breakpoint-down(md){
				opacity: 0.5;
	        }
		}
		.img-cover{
			left: -5px;
			right: -5px;
		}
	}
	.card-expertise-detail{
		padding: 10px 30px 30px 30px;
		position: absolute;
		bottom: 0;
		left: 0px;
		width: 100%;
		z-index: 10;
		@include media-breakpoint-down('lg'){
			padding: 10px 20px 30px 20px;
		}
		@include media-breakpoint-down('md'){
			padding: 0 15px 40px 15px;
		}
	

		.btn-circle{
			position: absolute;
			bottom: 20px;
			right: 20px;
			@include media-breakpoint-down(md){
        	    bottom: -60px;
			    right: -20px;
			    transform: scale(0.5);
	        }
		}
		.detail-title{
			font-size: 20px;
			font-weight: 500;
			margin-bottom: 10px;
		    position: absolute;
		 	bottom: 30px;
			transition: 0.22s ease-in-out;
			display: flex;
			align-items: center;
			@include media-breakpoint-down('md'){
				bottom: 100%;
			}
		}
		.detail-content{
			position: relative;
			transform: translateY(100%);
			opacity: 0;
			transition: 0.2s ease-in-out;
			padding-bottom: 100px;
			@include media-breakpoint-down('md'){
				transform: translateY(0%);
				opacity: 1;
				padding-bottom: 0px;
			}
			.cms{
				margin-bottom: 20px;
				color: $white;
			}
		}
	}


	&:hover{
		text-decoration: none;
		color: $white;
		.adv-image{
			&:after{
				opacity: 0.8;
			}
		}
		.card-expertise-detail{
			.detail-title{
				bottom: 100%;
			}
			.detail-content{
				transform: translateY(0%);
				opacity: 1;
			}
		}
	}
}


.section-outils{
	padding-top: 100px;
	padding-bottom: 200px;
}

#accordionOutils{
	.accordion-content-ctr{
		&:first-child{
			.accordion-content{
				border-top: 1px solid $dark-blue;
			}
		}
	}
	.accordion-content{
		border-bottom: 0px;
		.card-header{
			width: 100%;
			border-left: 0px;
			border-right: 0px;
			border-top: 0px;
			background: transparent;
      	    border: 0px;
      	    border-radius: 0px;
			.btn-card{
				position: relative;
				background: transparent;
				text-align: left;
				padding: 45px 110px 45px 0;
			    width: 100%;
				opacity: 1;
				transition: 0.2s linear;
				color:$red;
				border-bottom: 0px;

		        @include media-breakpoint-down(md){
			    	line-height: 1.5em;
			    	font-size: 18px;
		        }	
				.btn-circle{
					position: absolute;
					top: 0px;
					right: 0px;
					bottom: 0px;
					margin: auto;
					transform: rotate(-90deg);
					transition: 0.2s linear;
					border-color: $red;
			       
					svg{
				        [fill^="#"]{ fill : $red; }
				        [stroke^="#"]{ stroke : $red; }
					}
					
				}
				&.collapsed{
					color: $dark-blue;
					border-bottom: 1px solid $dark-blue;
					.btn-circle{
						transform: rotate(90deg);
						border-color: $dark-blue;
						svg{
					        [fill^="#"]{ fill : $dark-blue; }
					        [stroke^="#"]{ stroke : $dark-blue; }
						}
					}
					&:hover{
						.btn-circle{
							border-color: $red;
							svg{
						        [fill^="#"]{ fill : $red; }
						        [stroke^="#"]{ stroke : $red; }
							}
						}
					}

				}
			}
		}	
		.card-content{
   		    padding: 10px 0px 30px 0px;
			width: 100%;
			background: transparent;
			border-bottom: 1px solid transparent;
			transition: border-color 0.4s linear;
	        @include media-breakpoint-down(md){
	        	padding: 10px 0px 15px 0px;
	        }
			.card-body{
				margin-top: 0px;
				margin-bottom: 0px;
				padding: 0px;
			    padding-right: 160px;
	        	@include media-breakpoint-down(md){
			   		 padding-right: 0px;
				}
				&.cms{
				}
				.list-ref{
				    flex-wrap: wrap;
				    .item-ref{
		        		@include media-breakpoint-down(md){
		        			flex: auto;
		        			text-align: center;
					    }
				    }
				}
			}
			&.show{
				border-bottom: 1px solid $red;
			}
		}
	}
}

.section-secteur{
	position: relative;
	.container-fluid,
	.container-full{
		position: relative;
		z-index: 10;
	}
}

