/*
|
| Banner
|---------
*/
.banner{
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    .item-content{
        position: relative;
        z-index: 10;
        width: 100%;
        padding: 40px 0px;
    }
}

.banner--home{
    align-items: center;
    padding-top: 170px;
    padding-bottom: 0px;
	margin-bottom: 300px;
	background: $dark-blue;
	@include media-breakpoint-down(md){
    	padding-top: 90px;
        margin-bottom: 60%;
	}
    .btn-circle{
        position: absolute;
        bottom: 0;
        transform: translateY(50%);
        left: calc(50% + 85px );
        @include media-breakpoint-down(md){
            display: none;
        }
        .content-circle{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            p{
                margin-bottom: 10px;
            }
        }
    }
    .item-content{
        padding-bottom: 0px;
    }
	.item-title-ctr{
		.item-title {
			line-height: 1.1em;
		}
        .span-point{
            position: relative;
            bottom: -8px;
            left: -8px;
        }
	}
	.item-img-ctr{
		position: absolute;
		left: 0;
        bottom: -50px;
         @include media-breakpoint-down(md){
            position: absolute;
            order: 10;
            bottom: 0px;
            transform: translateY(30%);
        }
	}
	.item-text-ctr{
        padding-bottom: 130px;
        @include media-breakpoint-down(md){
            padding-bottom: 90%;
        }
		.cms{
			padding-left: 70px;
            @include media-breakpoint-down(md){
                padding-left: 0px;
            }
		}
	}
    .item-content{
        //opacity: 0;
        z-index: 20;
    }
    > a {
        z-index: 20;
    }
   
}

.banner--simple{
    padding-top: 120px;
    padding-bottom: 40px;
    background-color: $dark-blue;
    min-height: 450px;
}

.banner--actu{
    position: relative;
    padding-top: 100px;
    padding-bottom: 40px;
    background-color: $dark-blue;
    [data-webgl]:after{
        background: linear-gradient( $dark-blue 10%, rgba($dark-blue,0.5) 100% ) ;
    }
    .container-full{
        position: relative;
        z-index: 10;
    }
    .breadcrumb{
        color: $white;
    }
}


.banner--expertise{
    padding-top: 100px;
    padding-bottom: 40px;
    background-color: $dark-blue;
    min-height: 450px;
    flex-direction: column;
    .breadcrumb{
        margin-bottom: 80px;
        color: $white;
    }
    .container-full,
    .item-content{
        position: relative;
        z-index: 10;
        padding-top: 0px;
    }
    .bg-overlay{
        z-index: 0;
        &:before{
            background-color: $dark-blue;
            opacity: 0.9;
            z-index: 50;
            mix-blend-mode: multiply;
        }
    }
}
