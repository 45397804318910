.section-top-footer{
	.container-fluid{
		position: relative;
		z-index: 10;
	}
	.img-cover.bg-dark-blue{
		right: 0;
		left: auto;
		@include media-breakpoint-down(md){
			left: 0;
			height: 50%;
			top: auto;
		}
	}
	.img-cover.bg-red{
		left: 0;
		right: auto;
		@include media-breakpoint-down(md){
			left: 0;
			height: 50%;
			bottom: auto;
		}
	}
	.item-content{
		position: relative;
		padding-top: 200px;
		padding-bottom: 260px;
		@include media-breakpoint-down(md){
		    padding-top: 120px;
		    padding-bottom: 170px;
		}
		.elt-a{
			position: absolute;
			bottom: 80px;
			right: 60px;
			@include media-breakpoint-down(md){
				bottom: 45px;
    			right: 5px;
			}
		}

		&-left{
			.elt-a{}
		}
		&-right{
			.elt-a{
				right: 0px;
				@include media-breakpoint-down(md){
					right: 15px;
				}
			}
			padding-left: 50px;
			@include media-breakpoint-down(md){
				padding-left: 15px;
			}
		}
		
	}
}