/*
|--------------------
|       Contact
|--------------------
*/
body.page-template-contact{
    .cookie-popup{
        display: none !important;
    }
}
#page-contact{
   
}


.section-contact{
    .block-contact-ctr{
        padding: 40px;
        @include media-breakpoint-down('sm'){
            padding: 15px 0;
        }
    }
}

.list-info{
    a{
        &:hover{
            color:$red;
        }
    }
    .item-flex{
        display: inline-flex;
    }
    .item-info{
        padding-left: 0;
        margin-bottom: 20px;
        line-height: 1.5em; 
        &:last-child{
            margin-bottom: 0px;
        }
        .ico-info{
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 15px;
            font-size: 16px;
            svg{
                width: 100%;
                height: 100%;
                [fill^="#"]{fill : $red;}
                [stroke^="#"]{stroke : $red;}
            }
        }
    }
}