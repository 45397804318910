.section-confiance{
	position: relative;
	background: $red;
	overflow: hidden;
	.container-fluid{
		position: relative;
		z-index: 10;
	}
	.list-circle-img-ctr{
		display: flex;
		justify-content: space-around;
		height: 100%;

		@include media-breakpoint-down(md){
			display: none;
		}
	}
	.list-circle-img{
		top: 0;
		bottom: 0;
        height: 100%;
	    width: 1px;
	    .list-inner{
    		transform: translateY(50px);
    	}
	    &:nth-child(2n){
	    	.list-inner{
	    		transform: translateY(-10px);
	    	}
	    }
		.circle-img{
			margin-bottom: 20px;
		}
	}
}

.circle-img{
	width: 105px;
	height: 105px;
	border-radius: 100%;
	background: $white;
	overflow: hidden;
	img{
		height: 70%;
		width: 80%;
		max-height: 120px;
		object-fit: contain;
	}
	&.circle-img--xl{
		width: 130px;
		height: 130px;
	}
	&.border{
		border: 1px solid rgba($dark-blue,0.5);
		transition: 0.2s linear;
	}
}

.circle-link{
	&:hover{
		.circle-img{
			&.border{
				border-color:$red;
			}
		}

	}
}

.list-ref{
	padding-top: 20px;
	.item-ref{
		margin-right: 20px;
		margin-bottom: 20px;
	    @include media-breakpoint-down(sm) {
	    	flex: 0 0 50%;
	    	max-width: 50%;
	    	margin-right: 0px;
	    }

	}
}