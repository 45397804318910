/*
|--------------------------
|      DEFAULT & RESET
|--------------------------
*/
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*{
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body{
    font-family: $font-family-default;
    color: $default-color;
    font-size: $default-font-size;
    line-height: $default-line-height;
    font-weight: $default-font-weight;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6{
    @each $property, $value in $default-titles{
        #{$property}: $value;
    }
}

button, input, textarea{
  &:focus{
    outline: none !important;
    box-shadow: none !important;
  }
}

button{
    -webkit-appearance: none;
    padding: 0;
    background-color: transparent;
    border: none;
}

img{
  max-width: inherit;
}

a, img, span, button{
    display: inline-block;
}

a{
    color: $text-color;
    text-decoration: none;

    &:hover{
        color: $default-link-color;
    }
}

ul{
    margin: 0;
    padding: 0;
    
    li{
        list-style-type: none;
    }
}

.clearfix{
  clear: both;
  display: block;
}

.container{
  @extend .container-fluid;
}
@media (min-width: 1400px){
  .container-fluid{
       max-width: 1300px;
  }
}
.container-full{
    max-width: 1500px;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.title{
  .span-title{
    display: inline;
  }
  .span-point{
      position: relative;
      bottom: 0px;
      left: -8px;
      &.fill-dark-blue{
        polygon{
          fill: $dark-blue !important;
        }
      }
  }
  &.title-lg{
    .span-point{
       bottom: -4px;
       left: -4px;
    }
  }
}

