.section-team{
	padding-bottom: 200px !important;
	.list-team{}

	.col-img{
		.img-small{
	 	   max-width: 120px;
	 	   margin-right: 15px;
		}
	}
}

.card-team-ctr{
	margin-bottom: 30px;
	@include media-breakpoint-down(md){
		margin-bottom: 50px;
	}
}
.card-team{
	.p-r{
		&.with-link{
			.title{
				padding-right: 36px;
			}
		}
		.ul-rs{
			right: 0px;
			top: 0;
			position: absolute;
			.item-rs a{
				background: $very-light-grey;
				&:hover{
					.picto{
						background-color: $dark-blue !important;
						svg [fill^="#"] {
						    fill: $white !important;
						}
					}
				}
			}
		}
	}
	.card-img-ctr{
		position: relative;
		overflow: hidden;
		.card-text{
			position: absolute;
			z-index: 10;
			bottom : 0;
			left: 0 ;
			right: 0;
			height: 100%;
		    display: flex;
		    align-items: flex-end;
		    padding: 30px 20px;
		    font-size: 14px;
		    transform: translateY(100%);
		    background: linear-gradient(rgba($dark-blue,0) 0% ,rgba($dark-blue,0.3) 15%, rgba($dark-blue,0.7) 40%, rgba($dark-blue,1) 100%);
		    color: $white;
		    transition: 0.2s $easeInOutQuad;
			@include media-breakpoint-down(md){
		    	transform: translateY(0%);
	    	    position: relative;
    			background: $dark-blue;
			}
		}
	}
	
	.card-content{
		position: relative;
		flex: 2;
		padding-top: 20px !important;
	}

	&:hover{
		.card-img-ctr{
			.card-text{
		    	transform: translateY(0%);
			}
		}
	}
}