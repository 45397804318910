[data-webgl]{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
    z-index: 1;
    &:after{
        content: '';
        position: absolute;
        z-index: 10;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background: linear-gradient( $dark-blue, rgba($dark-blue,1) 55% ,rgba($dark-blue,0.9) 65%, rgba($dark-blue,0.5) 100% ) ;
        pointer-events: none;
    }

    &:before{
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        z-index: 1;
        background-image: url('../img/icon/Fichier 1@10x-8.png');
        background-repeat: repeat;
        background-position: center;
        pointer-events: none;
        background-size: 60px;
    }
    .webgl{
        mix-blend-mode: hard-light;
        opacity: 0.7;
    }

    &[data-position=right]{
        &:after{
            background: linear-gradient( to right, $dark-blue , $dark-blue 60%, rgba($dark-blue,0.5) 100% ) ;
        }
        &.bg-red{
            &:after{
                background: linear-gradient( to right, $red , $red 60%, rgba($red,0.5) 100% ) ;
            }
        }
    }


    &[data-position=left]{
        &:after{
            background: linear-gradient( to left, $dark-blue , $dark-blue 60%, rgba($dark-blue,0.5) 100% ) ;
        }
        &.bg-red{
            &:after{
                background: linear-gradient( to left, $red , $red 60%, rgba($red,0.5) 100% ) ;
            }
        }
    }

    &[data-position=rightandleft]{
        &:after{
            background: linear-gradient( to left, rgba($dark-blue,0.8), $dark-blue 24%, $dark-blue 76%, rgba($dark-blue,0.8) 100% ) ;
        }
        &.bg-red{
            &:after{
                background: linear-gradient( to left,  rgba($red,0.5), $red 40%, $red 60%, rgba($red,0.5) 100% ) ;
            }
        } 
    }

}