/*
|---------------------------------------------------------------
| BUTTONS
|---------------------------------------------------------------
| Generate and set buttons
|
|
*/

/*
|
| Buttons base default
|----------------------
|
*/
$button-default-padding-y :   20px;
$button-default-padding-x :   30px;
$button-default-font-size :   16px;
$button-default-icon-width :  30px;
$button-default-icon-height : 20px;
$button-default-icon-space :  40px;

$button-default : (
  position :        relative,
  font-family :     $font-family-default,
  display :         inline-block,
  padding :         $button-default-padding-y $button-default-padding-x,
  font-size :       $button-default-font-size,
  background:       $white,
  color:            $text-color,
  border:           1px solid $light-grey,
  border-radius:    4px,
  transition:       all 0.2s linear,
  box-shadow:       none !important,
  white-space:      inherit,
  font-weight:      200,
  cursor:           pointer
);

/*
|
| Buttons size setting
|-----------------------
|
*/
$button-xs-padding-y :    10px;
$button-xs-padding-x :    30px;
$button-xs-font-size :    14px;
$button-xs-icon-width :   30px;

$button-sm-padding-y :    10px;
$button-sm-padding-x :    20px;
$button-sm-font-size :    14px;
$button-sm-icon-width :   30px;

$button-md-padding-y :    10px;
$button-md-padding-x :    20px;
$button-md-font-size :    14px;
$button-md-icon-width :   30px;

$button-lg-padding-y :    10px;
$button-lg-padding-x :    20px;
$button-lg-font-size :    14px;
$button-lg-icon-width :   30px;


/*
|-------------------
| Buttons creation
|-------------------
| - How to use: $button-colors: map-set-buttons($map, $index, $background-color, $border-color, $text-color)
| - Generated classes: .btn-{size}, .btn-{color}, .btn-icon-{direction}.
|
*/
$button-colors: ();
$button-colors: map-set-buttons($button-colors, 'full-red', $red, $red, $white);
$button-colors: map-set-buttons($button-colors, 'red', $white, $red, $red);
$button-colors: map-set-buttons($button-colors, 'black', $black, $black, $white);
$button-colors: map-set-buttons($button-colors, 'white', transparent, $white, $white);
