/*
|--------------------
|      CONTENT
|--------------------
*/


/*
|
| Custom card
|
| Use in news archive page
|--------------
*/
.custom-card{

    .custom-card-link {
        display: block;
    }

    /*
    * Card img
    */
    .card-img-container {
        position: relative;
        overflow: hidden;

        &:after {
            content: '';
            display: block;
            padding-bottom: 100%;
        }

        .card-img {
            position: absolute;

            top: 0;
            left: 0;

            width: 100%;
            height: 100%;

            transition: transform 2s $easeOutExpo;
            will-change: transform;
        }
    }

    /*
    * Card content
    */
    .card-content {

        .date {
            color: rgba($very-dark-grey, 0.6);
        }
    }

    /*
    * Hover state
    */
    &:hover {

        .card-img-container {
    
            .card-img {
                transform: scale(1.05);
            }
        }
    }
    
    
}


/*
|
| Cookie banner
|----------------
*/
.cookie-popup {
    display: none;
    position: fixed;
    z-index: 888;
    bottom: 0;
    right: 0;
    width: 500px;
    max-width: 100%;
    padding: 30px 15px;
    background: $red;
    .item-close {
        position: absolute;
        top: 20px;
        right: 20px;
        font-size: 24px;
        cursor: pointer;
        svg path{
            fill: $white;
        }
    }


    @include media-breakpoint-down(sm) {
        padding: 50px 0 20px 0;
        font-size: 12px;
    }

    @include media-breakpoint-down(xs) {
        padding: 50px 0 20px 0;

    }
}


/*
|
| Pagination
|---------------
*/
.pagination-container {
    li {
        display: inline-block;

        a {
            transition: all 0.3s ease-out;
            padding: 0 8px;
            &:hover {
                color: $grey;
            }
        }

        .current {
            padding: 0 8px;
            font-weight: 800;
            color: $grey;
        }
    }
}


/*
|
| CMS
|------
*/
.cms-list{
    ul{
        margin-bottom: 40px;
        margin-top: 40px;
        li{
            position: relative;
            padding-left: 24px;
            margin-bottom: 8px;
            color: $text-color;
            &:before{
                content:'';
                position: absolute;
                display: inline-block;
                height: 8px;
                width: 8px;
                left: 1px;
                top: 8px;
                border-radius: 100%;
                background: $red;
            }
        }
    }
}
.item-cms{
    padding-left: 30px;
    padding-right: 30px;
    @include media-breakpoint-down(md){
        padding-right: 15px;
        padding-left: 15px;
    }
}
.cms{
    span{
        display: inline;
    }

    h1, h2, h3, h4, h5, h6{
        margin-bottom: 30px;
        color: $dark-blue;
    }

    h1{
        @extend .title-xl;
    }
    h2 {
        @extend .title-lg;
    }
    h3 {
        @extend .title-md;
    }
    h4 {
        @extend .title-sm;
    }
    h5 {
        @extend .title-sm;
    }
    h6 {
        @extend .title-xs;
    }

    img{
        display: block;
        width: auto;
        height: auto;
    }

    strong{
        font-weight: 800;
    }

    p{
        margin-bottom: 20px;
    }

    &.cms-no-break{
        p{
            margin-bottom: 0;
        }
    }

    blockquote {
        position: relative;
        
        width: 80%;
        
        margin-right: 0;
        margin-left: auto;
        padding: 40px 0;

        font-size: 30px;
        line-height: 1.5em;
        font-weight: 300;

        @include media-breakpoint-down(md){
            margin: auto;
        }

        &:before {
            content: "";

            position: absolute;
            
            background-image: url('../img/icon/quote-left.svg');
            
            width: 50px;
            height: 50px;
            

            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;

            transform: translateX(-60px) translateY(-30px);
        }

        &:after {
            content: "";

            position: absolute;
            right: 0;
            
            background-image: url('../img/icon/quote-right.svg');
            
            width: 50px;
            height: 50px;
            

            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;

            transform: translateX(0) translateY(-20px);

            @include media-breakpoint-down(md){
                transform: translateX(60px) translateY(-50px);
            }
        }
    }
    ol{
        > li {
            padding-left: 30px;
        }
    }
     ul{
        li{
            position: relative;
            padding-left: 24px;
            margin-bottom: 8px;
            color: $text-color;
            &:before{
                content:'';
                position: absolute;
                display: inline-block;
                height: 8px;
                width: 8px;
                left: 1px;
                top: 11px;
                background-image: url('../img/icon/title-point.svg');
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center; 
            }
        }
    }
}


/*
|
| Page offset
|--------------
*/
.page-offset {
    padding-top: 170px;
    @include media-breakpoint-down(lg) {
        padding-top: $headerHeightSm;
    }
}


/*
|
| Custom Loader
|---------------
*/
.custom-loader {
    width: 100px;
    display: inline-block;

    &.cl-center {
        margin-left: auto;
        margin-right: auto;
    }

    svg {
        display: inline-block;
        width: 100%;
        height: auto;
        fill: $black;

        circle {
            stroke: $black;
        }
    }

    &.ajax-loader {
        display: none;
    }
}
