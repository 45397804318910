#customCursor{
    position: fixed;
    z-index: 9999;
    left: 0;
    top: 0;
    pointer-events: none;
    margin-left: -15px;
    margin-top: -15px;
    width: 30px;
    height: 30px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    z-index: 8000;
    border-radius: 100%;
    transition: all .1s ease-out; 
    background-color: $orange;
    opacity: 0;
    transform: scale(0.9);
    &.cursor-link-hover{
        opacity: 1;
        transform: scale(1);
        mix-blend-mode: screen; 
        transition: all .1s ease-out; 
    }
}
