[data-splittext]{
    opacity: 0;

    &.split-ready {
        opacity: 1;
    }
}

[data-kira-item="splitline"] {
    .item-line {
        overflow: hidden;
        display: inline-block !important;
        line-height: 1.2em;
        @include media-breakpoint-down(md){
        }
    }
    .span-point{
        left : -6px !important;
    }
    .title-xl{
        .span-point{
            @include media-breakpoint-down(md){
                left: -4px !important;
                bottom: -5px !important;
            }
        }
    }
}

[data-kira-item^="fadeIn"] {
    opacity: 0;
}

[data-kira-item^="fadeInLeft.stagger"],
[data-kira-item^="fadeInUp.stagger"]{
    opacity: 1;

    [data-stagger-item]{
        opacity: 0;
    }
}



