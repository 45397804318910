.img-fluid{
	width: 100%;
	max-width: 100%;
}
.img-cover{
	position: absolute; 
	left: 0;
	right: 0; 
	top: 0; 
	bottom:0;
	overflow: hidden;
	figure{
		height: 100%;
		width: 100%;
		margin: 0px;
		.img-fluid{
			object-fit: cover;
			height: 100%;
			width: 100%;
		}
	}
	&.style-contain{
		figure{
			.img-fluid{
				object-fit: contain !important;
			}
		}
	}
}
.adv-image-ctr{
   	&.flat-red{
   		&:before{
			position: absolute;
			display: block;
			content: "";
			display : inline-block;
			height : 0;
			width : 0;
			border-right: 70px solid $red;
 			border-bottom: 40px solid $white;
			left: 0;
			bottom: 0;
			z-index: 2;
	    }
   		&:after{
			content: "";
   			position: absolute;
   			z-index: 1;
   			top: 20%; bottom: 0;
   			left: 0; right: 0;	
   			width: 90%;
   			margin: auto;
   			background: $red;
   		}
   		&-left{
	   		&:before{
	   			transform: translate(-50px , 45px);
	   		}
   			&:after{
   				top: 20%; bottom: 0;
	   			right: auto; 
	   			transform: translate(-50px , 45px);
   			}
   		}
   		&-right{
   			&:before{
   				border-right: 0px;
				border-left: 70px solid $red;
   				right: 0; left: auto;
	   			transform: translate(50px , 70px);
	   		}
   			&:after{
   				top: 20%; bottom: 0;
	   			right: 0; left: auto;
	   			transform: translate(50px , 70px);
   			}
   		}

   		&-sm{
		    width: 70%;
		    @include media-breakpoint-down(sm) {
		 	   width: 100%;
		 	    &:after{
		 	   		transform: translate(50px, 40px);
		 	    }
	   			&:before{
		 	   		transform: translate(40px, 40px);
		 	   }
		    }
   		}
   	}
}

.adv-image{
	position: relative;
    display: block;
    width: 100%;
    padding: 0;
    &:before{
		display: block;
		content: "";
		padding-top: 120%;
    }
    &.adv-image--video{
    	&:before{
    		padding-top: 60%;
    	}
    }
   	&.adv-image--1by1{
   		&:before{
   			padding-top: 100%;
   		}
   	}
	&.adv-image--actu{
   		&:before{
   			padding-top: 60%;
   		}
   	}
   	&.adv-image--expertise{
   		&:before{
			padding-top: 130%;
   		}
   	}
   	&.adv-image--presentation{
   		&:before{
   			padding-top: 90%;
			@include media-breakpoint-down(md){
				padding-top: 105%;
   			}
   		}
   	}
   	.img-cover{
   		z-index: 3;
   		overflow: hidden;
   	}

}

.ico-target{
	width: 12px;
	height: 12px;
	display: flex;
	justify-content: center;
	align-items: center;
	svg{
		width: 100%;
		height: 100%;
	}
}


.bg-overlay{
	&:before{
		content: '';
		position: absolute;
		top:0;
		left: 0 ; 
		right: 0;
		bottom: 0;
		background-color: $black;
		opacity: 0.3;
		z-index: 50;
	}
	> * {
		z-index: 10;
	}
	
	&--gradient{
		&:before{
			opacity: 1;
			background: rgb(0,0,0);
			background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.6) 100%);
		}
	}
}


[data-img-parallax]{
	--overflow: 80px !important;
	height: calc(100% + (2 * var(--overflow))) !important;
	top: calc( -1 * var(--overflow)) !important;
}
