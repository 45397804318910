
/*
|
| Menu
|-------
*/
#mobile-menu{
    position: fixed;
    z-index: 777;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: $dark-blue;
    overflow: auto;
    opacity: 0;
    visibility: hidden;
    padding-top: 80px;
    padding-bottom: 80px;
    @include media-breakpoint-up(xl) {
        display: none !important;
    }
    .item-link{
        padding-right: 15px;
        padding-left: 15px;
    }
    .item-container{
        @extend .container;
        padding-left: 0px;
        padding-right: 0px;
        //padding-top: 150px;
        //padding-bottom: 50px;

        > ul {
            padding-top: 0;
            > li {
                margin-bottom: 15px;
                >  a {
                    color: $white;
                    font-size: 16px;
                    line-height: 2em;
                    font-weight: 400;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    font-family: $font-family-custom;
                    -webkit-font-smoothing: antialiased;
                    display: flex;
                    align-items: center;
                    &:hover,
                    &:focus{
                        text-decoration: none;
                        color: $light-grey;
                        font-weight: 600;
                    }
                    .ico-target{
                        transform: rotate(180deg);
                        margin-left: 20px;
                    }
                    &.collapsed{
                         .ico-target{
                            transform: rotate(0deg);
                        }
                    }
                }
                &.lang-item{
                    float: left;
                }
            }
        }
    }

    .header-submenu{
        margin-top: 0px;
        padding: 0 15px;
        .nav-submenu{
            padding-top: 0px;
            margin-bottom: 0;
            padding-left: 15px;
            li:not(:last-child){
            }
            li{
                .item-sub-link{
                    padding-bottom: 7px;
                    padding-top: 7px;
                    color: $grey;
                    line-height: 2em;
                    font-weight: 200;
                    font-family: $font-family-custom;
                }
            }
        }
    }
    .ul-rs{
        justify-content: flex-start;
        margin-top: 10px;
        padding-left: 15px;
        padding-right: 15px;
    }
    .second-menu{
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        .list-btn{
            li{
                &:not(:last-child){
                   margin-bottom: 15px;    
                }
                .btn{
                    width: 100%;
                    border-radius: 0px;
                }
            }
        }
    }
    .lang-item{
        margin-top: 10px;
        margin-left: 15px;
        .item-link{
            display: inline-flex;
            justify-content: center;
            align-items: center;
            padding: 7px;
            height: 32px;
            width: 32px;
            margin: 0px;
            background: #FFFFFF;
            transition: 0.2s linear;
        }
    }
}

