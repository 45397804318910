.section-presentation{
	overflow: hidden;
	position: relative;
	margin-bottom: 30px;
    @include media-breakpoint-down(sm) {
    	display: flex;
    	flex-direction: column;
    	padding-bottom: 30px;
    }
    .adv-image-ctr{
    	
    }
    .content-item-ctr{
	    display: flex;
	    justify-content: center;
	    align-items: center;
    }
    .adv-image-ctr.flat-red-sm{
	    @include media-breakpoint-down(sm) {
	    	width: 100%;
	    }
    }
	.content-item{
		position: relative;
		z-index: 10;
		padding-top: 80px;
		padding-bottom: 80px;
		padding-left: 15px;
		padding-right: 100px;
		@include media-breakpoint-down(sm) {
			padding-top: 80px;
			padding-bottom: 0px;
			padding-left: 30px;
			padding-right: 30px;
	    	text-align: center;
	    }
	}
	.col-custom{
		flex: 0 0 45%;
    	max-width: 45%;
	    @include media-breakpoint-down(sm) {
	    	flex: 0 0 100%;
    		max-width: 100%;
	    }
	}
	.ul-rs{
		a{
			&:hover{
				p {color: $dark-blue !important;}
				.picto{
					background-color: $dark-blue !important;
					svg [fill^="#"] {
					    fill: $white !important;
					}
				}
			}
		}
	}
	.list-btn{
		
	}

	.container-fluid{
		.row-custo{
		}
	}
	
	&-img-left{
		.row-custo{
			flex-direction: row-reverse;
		}
		.content-item{
		    padding-left: 15px;
			padding-right: 100px;
		    @include media-breakpoint-down(sm) {
			    padding-left: 15px;
				padding-right: 15px;
		    }
		}
	}
	&-img-right{
		.content-item{			
			padding-left: 100px;
			padding-right: 15px;
			@include media-breakpoint-down(sm) {
			    padding-left: 15px;
				padding-right: 15px;
		    }
		}
	}
}
