/*
|      Principal bottom
|--------------------
*/
$bg_principal-color: transparent;
$item_principal-color: $white;
$item_principal-hover-color: $red;


@keyframes navSlideDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

#header {
  &.slide-down {
    animation-name: navSlideDown;
    animation-duration: 1s;
  }

  &.floating {
    position: fixed;
    background-color: $white;
    box-shadow: 0px 6px 13px #00000014;

    .item-link {
      color: $dark-blue;
    }

    .ul-rs .item-rs a {
      .picto {
        background: $dark-blue;

        svg {
          [fill^="#"] {
            fill: $white;
            transition: 0.2s linear;
          }

          [stroke^="#"] {
            stroke: $white;
            transition: 0.2s linear;
          }
        }
      }

      &:hover {
        .picto {
          background: $red;
        }
      }
    }

    .header-container {
      .item-logo {
        svg {
          [fill^="#"]:not(.point) {
            fill: $dark-blue;
            transition: 0.2s linear;
          }

          [stroke^="#"]:not(.point) {
            stroke: $dark-blue;
            transition: 0.2s linear;
          }
        }
      }
    }

    .btn-menu > .item-burger > span {
      background-color: $dark-blue;
    }
    .lang-item{
      .span-title{
        background-color: $dark-blue;
      }
    }
  }

  .lang-item {
    //-webkit-filter: grayscale(100%); /* Chrome, Safari, Opera */
    //filter: grayscale(100%);

    &.current-lang {
      //-webkit-filter: grayscale(0%); /* Chrome, Safari, Opera */
      //filter: grayscale(0%);
    }

    .item-link {
      padding: 20px 12px;
    }

    .span-point {
      display: none !important;
    }
    .span-title{
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 7px;
      height: 32px;
      width: 32px;
      margin: 0px;
      background: #FFFFFF;
      transition: 0.2s linear;
    }
  }

}


#header {
  width: 100%;
  position: absolute;
  z-index: 888;
  @include media-breakpoint-down('lg') {
    top: 0;
  }

  .item-link {
    color: $item_principal-color;
    font-weight: 200;
    padding: 20px 26px 20px 26px;
    @include media-breakpoint-down('xl') {
      padding: 10px 12px;
    }
    @media(max-width:1230px) {
      padding: 10px;
    }

    .span-title {
      transition: 0.2s $easeInOutQuad;
    }

    .span-point {
      opacity: 0;
      position: relative;
      top: 1px;
      left: 2px;
      transform: translateX(-100%);
      transition: 0.2s linear;

      svg {
        width: 7px;
        height: 7px;
      }
    }

    &:hover {
      text-decoration: none;

      .span-title {
        color: $item_principal-hover-color;
      }

      .span-point {
        transform: translateX(0%);
        opacity: 1;
      }
    }
  }

  .item-sub-link {
    padding: 10px 30px 10px 30px;
  }

  /*
  |      MENU PRINCIPAL
  |--------------------
  */
  .header-principal {
    position: relative;
    z-index: 5;
    background-color: $bg_principal-color;

    .list-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      @include media-breakpoint-down('lg') {
        display: none;
      }

      .btn {
        border-radius: 0px;
        padding: 30px 50px;
        @media(max-width:1500px) {
          padding: 30px 30px;
        }
        @media(max-width:1230px) {
          padding: 30px 25px;
        }
      }
    }

    .header-container {

    }
  }

  .header-submenu {
    z-index: 1;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: auto;
    top: auto;
    background: $dark-blue;
  }


  /*
  |      Global
  |--------------------
  */
  .header-container {
    //@extend .container-fluid;
    display: flex;
    padding: 0px;
    @include media-breakpoint-down('lg') {
      padding: 15px;
    }

    .nav-ctr {
      flex: 1;
      display: flex;
    }

    .logo-ctr {
      margin-left: 50px;
      @include media-breakpoint-down(xl) {
        margin-left: 10px;
      }
      @include media-breakpoint-down(lg) {
        margin-left: 0;
        margin-top: 5px;
      }
    }

    .item-logo {
      width: 220px;
      height: 35px;
      justify-content: center;
      align-items: center;
      @include media-breakpoint-down('lg') {
        width: 170px;
      }
      @include media-breakpoint-down('lg') {
        padding-bottom: 0px;
      }
      @include media-breakpoint-down(md) {
        height: 40px;
        width: 120px;
      }

      svg {
        width: 100%;
        height: 100%;

        [fill^="#"]:not(.point) {
          fill: $white;
          transition: 0.2s linear;
        }

        [stroke^="#"]:not(.point) {
          stroke: $white;
          transition: 0.2s linear;
        }
      }
    }

    .item-ul {
      display: flex;

      .menu-item {
        &.current_page_item {
          .item-link {
            .span-title {
              color: $red;
            }

            .span-point {
              opacity: 1;
              transform: translateX(0%);
            }
          }
        }
      }
    }

    .item-ul,
    .ul-rs,
    .item-nav {
      display: none;
      @include media-breakpoint-up(xl) {
        display: flex;
        .item-ul {
        }
      }
    }
  }

  li.menu-item-has-children {
    span.ico-target {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 20px;
      width: 10px;
      height: 10px;
      margin: auto;
      transition: 0.2s linear;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: rotate(90deg);

      svg {
        width: 100%;
        height: 100%;

        path {
          fill: $white;
        }
      }
    }

    &:hover {
      span.ico-target {
        transform: rotate(-90deg);
      }
    }
  }

  .btn-menu {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    //width: 56px;
    //height: 56px;
    margin-top: 5px;
    padding: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;

    & > .item-burger {
      display: block;
      width: 25px;

      & > span {
        display: block;
        width: 100%;
        height: 2px;
        background-color: $white;
        border-radius: 2px;

        &:nth-child(2) {
          margin: 6px 0;
        }
      }
    }

    @include media-breakpoint-up(xl) {
      display: none;
    }
  }

}


/*
|      Sub Menu
|--------------------
*/
$bg_sub_menu-color: $white;
$item_sub-color: $red;
$item_sub-hover-color: $dark-red;
.header-principal {
  &.sub-menu-simple {
    .item-nav .item-ul .menu-item {
      position: relative;
    }

    .menu-panel {
      top: 0;
      left: 0;
      right: 0;
      min-width: 100%;
      width: max-content;
      position: absolute;
      background-color: $bg_sub_menu-color;
      padding: 5px 15px 5px 15px;
      transition: opacity 1.2s $easeSmooth, transform 2s $easeSmooth;
      transform: translateY(-10px);
      box-shadow: 0px 2px 4px 0px rgba($black, 0.2);

      li {
        padding: 5px;
        font-size: 16px;
      }
    }
  }
}
