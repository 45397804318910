/*
|--------------------
|      BUTTONS
|--------------------
*/
.link-simple{
	@extend .btn;
	position: relative;
	background: transparent;
	padding: 6px 90px 6px 0px;
	color: $white;
	border: 0px;
	transition: all 0.2s linear;
	.icon{
	    position: absolute;
	    top: 0px;
	    bottom: 0px;
	    right: 0px !important;
	    margin: auto;
	    display: flex;
	    align-items: center;
	    justify-content: center;
	    height: 100%;
	    transition:  all 0.2s linear;
	    width: 80px;
	    height: 80px;
        border-radius: 100%;
        border: 1px solid $white;
		@include media-breakpoint-down(sm) {
			transform: scale(0.7);
        }
        svg{
        	width: 20px;
			height: 20px;
		 	[fill^="#"]{ fill : $white;transition:  all 0.2s linear; }
	        [stroke^="#"]{ stroke : $white;transition:  all 0.2s linear;}
        }	
	}
	&:hover{
		.icon{
			border-color: $dark-blue;
        	svg{
        		[fill^="#"]{ fill : $dark-blue; }
	        	[stroke^="#"]{ stroke : $dark-blue;}
			}
		}
	}
	&.link-white{
		&:hover{
			color: $red;
			.icon{
				svg{
	        		[fill^="#"]{ fill : $red; }
		        	[stroke^="#"]{ stroke : $red;}
				}
			}
		}
	}
	&.link-red{
		color: $red;
		.icon{
			border-color: $red;
			background-color: $red;
        	svg{
        		[fill^="#"]{ fill : $white; }
	        	[stroke^="#"]{ stroke : $white;}
			}
		}

		&:hover{
			color: $dark-red;
			.icon{
				border-color: $dark-red;
				background-color: $dark-red;
			}
		}
	}
	&.btn-icon-left{
		padding: 6px 15px 6px 90px !important;
		.icon{
			right: auto  !important;
			left: 0;
		}
	}

	&.no-border{
		.icon{
			border: 0px;
		}	
	}
	&.btn-back{
		padding: 6px 15px 6px 50px !important;
		.icon{
			transform: rotate(180deg);
		}
	}
	&.btn-icon-md{
		&.btn-icon-right{
			padding-right: 80px!important;
		}
		.icon{
			width: 50px;
			height: 50px;
		}
	}
	&.btn-icon-sm{
		.icon{
			width: 15px;
			height: 15px;
	        svg{
				width: 15px;
				height: 15px;
			}
		}
	}
	
}


.btn-simple{
	background: transparent;
	border-color: transparent;
	&.btn-icon-right{
	    padding-right: 70px;
	}
	.icon{
		background: $red;
		border-radius: 100%;
   		width: 40px;
		height: 40px;
	}
	&.btn-simple-white{
		color: $white;
		.icon{
			background: $white;
			border-radius: 100%;
	   		width: 40px;
    		height: 40px;
    		svg{
		        [fill^="#"]{ fill : $red; transition: all 0.2s linear;}
		        [stroke^="#"]{ stroke : $red; transition: all 0.2s linear;}
			}
		}
	}
	.text{
		position: relative;
	}
}




.btn-icon{
    display: inline-flex;
    align-items: center;
	.text{
		transition:all 0.2s linear;;
	}

	&.btn-icon-right{
		@include media-breakpoint-down(sm) {
		    padding-right: 80px;
		    .icon{
		    	right: 20px;
		    }
		}
	}
	&.btn-icon-left{
		padding-left: 50px;
		.icon{
			left: 0px;
		}
	}
	&.btn-red{
		svg{
	        [fill^="#"]{   fill : $red; transition:all 0.2s linear;}
	        [stroke^="#"]{   stroke : $red; transition: all 0.2s linear;}
		}
		&:hover{
			color: $white;
			background: $red;
			.text{ color: $white;}
			svg{
                [fill^="#"]{  fill : $white;}
                [stroke^="#"]{  stroke : $white; }
			}
		}
	}
	&.btn-white{
		svg{
	        [fill^="#"]{ fill : $white; transition:all 0.2s linear;}
	        [stroke^="#"]{ stroke : $white;transition:all 0.2s linear;}
		}
		&:hover{
			background: $white;
			.text{ color: $red;}
			svg{
                [fill^="#"]{  fill : $red;}
                [stroke^="#"]{   stroke : $red;}
			}
		}
	}
}

.btn-full-red {
	&:hover{
		background: $dark-red;
	}
}


.btn-circle{
	height: 76px;
	width: 76px;
	justify-content: center;
	align-items: center;
	display: flex;
	border-radius: 100%;
	transition: 0.2s linear 0s;
	border: 2px solid $red;
	background: $red;
	&.btn-circle-xs{
		height: 54px;
		width: 54px;
	}
	svg{
        [fill^="#"]{ fill : $white; transition: all 0.2s linear;}
        [stroke^="#"]{ stroke : $white; transition:all 0.2s linear;}
	}
	&.btn-circle-border{
		border: 1px solid $dark-blue;
		background: $white;
		svg{
	        [fill^="#"]{ fill : $dark-blue; }
	        [stroke^="#"]{ stroke : $dark-blue; }
		}
	}

	&.btn-circle-xl{
		width: 140px;
		height: 140px;
	}
}

