.section-faqs {
	.schema-faq-section {
		width: 100%;
		display: block;
		border: 1px solid black;
		margin-bottom: 25px;
		border-radius: 8px;
		padding: 10px 0 10px 0;
		.schema-faq-question {
			font-family: "GT-America-ExtendedBlack", sans-serif;
			font-style: normal;
			font-weight: 900;
			font-size: 22px;
			line-height: 27px;
			position: relative;
			display: block;
			cursor: pointer;
			padding: 25px 65px 25px 30px;
			font-weight: bold;
			font-size: 20px;
			&:after {
				content: "";
				position: absolute;
				background-image: url("../img/icon/plus.svg");
				background-repeat: no-repeat;
				background-position: center;
				right: 25px;
				top: 32%;
				height: 29px;
				width: 29px;
				display: flex;
				align-items: center;
				justify-content: center;
				color: $red;
			}
		}
		.schema-faq-answer {
			display: none;
			margin: 0 50px 20px 20px;
		}
		&.active {
			border: 1px solid $red;
			.schema-faq-question {
				border-radius: 0;
				border-top-right-radius: 8px;
				border-top-left-radius: 8px;
				&:after {
					content: "";
					background-image: url("../img/icon/moins.svg");
					height: 29px;
					width: 29px;
				}
			}
		}
	}
}
