[data-item-video]{
  
  [data-img-cover]{
    position: relative;
    z-index: 20;
  }
  .videobg {
      position: absolute;
      width: 100%;
      height: 100%;
      overflow: hidden;
      top: 0;
      left: 0;
      z-index: 1;
      &:after{
        content: '';
        position: absolute;
        z-index: 1;
        left:0;
        right:0;
        top: 0;
        bottom: 0;
      }
  }  
}

.videobg-width {
  position: absolute;
  width: 100%; 
  height: 100%;
  left: -9999px;
  right: -9999px;
  margin: auto;
}
.videobg-aspect {
  position: absolute;
  width: 100%;
  height: 0;
  top: -9999px;
  bottom: -9999px;
  margin: auto;
  padding-bottom: 56.25%; /* 16:9 ratio */
  overflow: hidden;
}
.videobg-make-height {
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
}

.videobg-hide-controls {
  box-sizing: content-box;
  position: relative;
  height: 100%;
  width: 100%;
  /* Vimeo timeline and play button are ~55px high */
  padding: 55px 97.7777px; /* 16:9 ratio */
  top: -55px; 
  left: -97.7777px; /* 16:9 ratio */
}

.videobg iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
	border: 0 none;
	z-index: 51;
}