.section-valeurs{
	position: relative;
	padding-top: 70px;
	padding-bottom: 70px;
	margin-bottom: 100px;
	background: $dark-blue;
	@include media-breakpoint-down(md){
		margin-bottom: 50px;
	}
	.container-fluid{
		position: relative;
		z-index: 10;
	}
}

.list-valeur{
	.item-valeur-ctr{
		margin-bottom: 30px;
		@include media-breakpoint-down(md){
			margin-bottom: 0px;
		}
	}
	.item-icone{
		height: 60px;
		width: 60px;
		margin-bottom: 20px;
		@include media-breakpoint-down(md){
	    	margin-top: 20px;
	    	margin-bottom: 10px;
		}
		img{
			height: 100%;
			width: 100%;
			object-fit: contain;
		}
	}
	.item-valeur{
		padding-top: 15px;
		padding-right: 50px;
		.data-title{
			margin-bottom: 30px;
			font-size: 18px;
		}
		.data-text{
			p{
			}
		}
	}

	&.list--offre{
		margin-top: 50px;
		.item-icone{
			height: 120px;
			width: 120px;
			margin: auto;
			background: $red;
			border-radius: 100%;
			display: flex;
			justify-content: center;
		    align-items: center;
		    margin-bottom: 20px;
			@include media-breakpoint-down(md){
		    	margin-top: 20px;
		    	margin-bottom: 0px;
			}
			img{
				height: 50%;
				width: 50%;
			}
		}
		.item-valeur{
			padding-right: 0px;
			text-align: center;
			.data-title{
				margin-bottom: 20px;
				@extend .title-xs;
				color: $white;
			}
		}
	}
}

.section{
	> .p-r{
		z-index: 10;
	}
}
